@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
//@import "~@ng-select/ng-select/themes/material.theme.css";
//@import "~@ng-select/ng-select/themes/default.theme.css";
@import "app/theme/styles/spinner";
@import "@angular/material/prebuilt-themes/indigo-pink.css"; 
@import '~swiper/swiper-bundle.min.css';
@import "app/theme/styles/base"; 
@import "app/theme/styles/admin";
@import "app/theme/styles/spaces";
@import "app/theme/styles/theme";
@import "app/theme/styles/gradients";
@import "app/theme/styles/rtl"; 
@import "app/theme/styles/material.theme.scss"; 

.overlay{
    height:100vh;
    width:100%;
    background-color:rgba(0, 0, 0, 0);
    z-index:    10;
    top:        0; 
    left:       0; 
    position:   fixed;
  }
  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}
.mat-menu-panel {
  max-height: calc(40vh) !important;
}